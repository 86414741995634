<template>
    <div class="w-100 overflow-hidden banner-container d-flex justify-content-center align-center justify-center" style="height: 275px">
        <div class="banner-overlay"></div>
        <img class="banner" :style="'transform: translate3d(0px, ' + ($store.state.window.offsetY / 3) + 'px, 0px);'"  alt="banner" src="../assets/img/bg-banner-2.jpg">
    </div>
</template>
<script>
export default {
    name: 'PageHeader'
}
</script>
<style scoped>
    .banner-container{
        position: relative;
    }
    .banner {
        object-fit: cover;
        object-position: 50% 50%;
        max-width: none;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        position: relative;
        margin-top: 0px;
    }
    .banner-overlay:before {
        content:"";
        position: absolute;
        top:0;
        right:0;
        left:0;
        bottom:0;
        z-index:1;
        background:linear-gradient(to right,rgba(0, 0, 0, 0.8) ,rgba(0, 0, 0, 0.9));
    }
</style>